.client-dashboard-plot {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #fff;
}
.js-plotly-plot {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    background-color: #fff;
}