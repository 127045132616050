.top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: bisque;
}
.top-header-title {
    display: flex;
    padding: 10px;
}
.top-header-navigation {
    display: flex;
    padding: 10px;
}
.top-header-navigation a {
    display: flex;
    padding: 0px 10px;
}

.main-content {
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.presenter-top-container {
    display: flex;
    flex-direction: column;
    border: 1px solid black;
}
.presenter-container-header {
    display: flex;
    margin: 10px;
}

.presenter-container {
    display: flex;
}

.presenter-wrapper {
    display: flex;
    border: 1px solid black;
    margin: 10px;
    padding: 5px;
}


.universal-info {
    margin: 10px;
    padding: 10px;
    background-color: white;
    border: 1px solid gray;
}
table.universal-table th {
    text-align: right;
    padding: 2px 5px;
}

table.universal-table td {
    text-align: left;
    padding: 2px 5px;
}
textarea.universal {
    height: 200px;
    width: 40%;
}

div.padding {
    padding: 10px;
}